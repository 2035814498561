import { KEY_FEATURES_CONTENT } from "../constants";
import { delay, motion } from 'framer-motion';

const KeyFeatures = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                straggerChildren: 0.2,
                when: 'beforeChildren'
            }
        }
    }

    const featureVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    }

    return (
        <section id="products">
            <div className="max-w-7xl mx-auto px-4 mt-20">
                <div className="text-center mb-12 border-t border-neutral-800">
                    <h2 className="text-3xl lg:text-6xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {KEY_FEATURES_CONTENT.sectionTitle}
                    </h2>
                    <p className="mt-4 text-lg font-medium">
                        {KEY_FEATURES_CONTENT.sectionDescription}
                    </p>
                </div>

                <motion.div
                    variants={containerVariants}
                    initial='hidden'
                    whileInView='visible'
                    className="flex flex-wrap justify-between mb-10"
                >
                    {KEY_FEATURES_CONTENT.features.map((feature) => (
                        <motion.div
                            key={feature.id}
                            variants={featureVariants}
                            className="flex flex-col items-center text-center w-full md:w-1/2 lg:w-1/3 p-6 cursor-pointer"
                            onClick={() => window.open(feature.pdfPath, '_blank')}
                        >
                            <div className="flex justify-center items-center mb-4">
                                <img src="https://sepm-bucket.s3.eu-west-3.amazonaws.com/SEPM-Logo.png" alt='logo' width={48} height={14} />
                            </div>

                            <h3 className="text-xl">{feature.title}</h3>
                            <p className="mt-2 text-neutral-400">{feature.description}</p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    )
}

export default KeyFeatures