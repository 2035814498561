import { FOOTER_CONTENT } from "../constants"

const Footer = () => {
    return (
        <footer className="mt-20 text-neutral-400">
            <div className="max-w-7xl mx-auto px-4 border-t border-neutral-800">
                <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-20">

                    <div>
                        <div className="text-neutral-600 font-medium mb-4">{FOOTER_CONTENT.contactUsText}</div>
                        {FOOTER_CONTENT.contactUsDesc.split("\n").map((text, index) => (
                            <span className="text-StandardSEPM font-medium" key={index}>
                                {text}
                                <br />
                            </span>
                        ))}

                    </div>

                    {FOOTER_CONTENT.sections.map((section, index) => (
                        <div key={index}>
                            <h3 className="text-neutral-600 font-medium mb-4">{section.title}</h3>
                            <ul className="space-y-2 text-StandardSEPM">
                                {section.links.map((link, index) => (
                                    <li key={index}>
                                        <a className="hover:font-semibold" href={link.url} target="_blank">{link.text}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className="my-12 border-t border-neutral-800 pt-8 text-center text-sm text-neutral-500">
                    <div className="flex justify-between">
                        <div className="text-xs">
                            <p>{FOOTER_CONTENT.platformsText}</p>
                        </div>
                        <div className="text-xs">
                            <p>{FOOTER_CONTENT.copyrightText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer