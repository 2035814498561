import { DOWNLOADS, NEW_FEATURE } from "../constants";
import { motion } from 'framer-motion';

const DownloadLinks = () => {
    const stepVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.6,
                ease: "easeOut"
            }
        })
    }

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    }

    return (
        <section id="downloads">
            <div className="max-w-7xl mx-auto px-4 border-t border-neutral-800">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className="text-center mb-12 "
                >
                    <h2 className="text-3xl lg:text-6xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {DOWNLOADS.sectionTitle}
                    </h2>
                    <p className="mt-4 text-[16px] font-medium text-StandardSEPM max-w-xl mx-auto">
                        {DOWNLOADS.sectionDescription}
                    </p>
                </motion.div>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={{
                        visible: {
                            transition: {
                                staggerChildren: 0.2,
                            },
                        },
                    }}
                    className="flex flex-wrap md:flex-nowrap gap-6"
                >
                    {/* Left Section: Features */}
                    <motion.div className="flex w-full md:w-full m-4 justify-center items-center ">
                        <motion.div
                            variants={stepVariants}
                            className="flex flex-wrap md:flex-nowrap justify-center items-center gap-14"
                        >
                            <div className="flex flex-col items-center cursor-pointer">
                                {/* Icon Container */}
                                <a
                                    href={DOWNLOADS.downloadLink}
                                    download
                                    className="text-lg border border-StandardSEPM p-2 flex-shrink-0 w-16 h-16 flex justify-center items-center transition-transform duration-300 ease-in-out hover:scale-110"
                                >
                                    {DOWNLOADS.downloadIcon}
                                </a>

                                {/* File Name */}
                                <div className="text-center mt-2 text-sm">{DOWNLOADS.downloadFile}</div>
                                
                            </div>



                            <div className="flex flex-col items-center cursor-pointer">
                                {/* Icon Container */}
                                <a
                                    href={DOWNLOADS.releaseNote_EN}
                                    target="_blank"
                                    className="text-lg border border-StandardSEPM p-2 flex-shrink-0 w-16 h-16 flex justify-center items-center transition-transform duration-300 ease-in-out hover:scale-110"
                                >
                                    {DOWNLOADS.pdfIcon_EN}
                                </a>

                                {/* File Name */}
                                <div className="text-center mt-2 text-sm">Release Notes English</div>
                            </div>

                            <div className="flex flex-col items-center cursor-pointer">
                                {/* Icon Container */}
                                <a
                                    href={DOWNLOADS.releaseNote_GE}
                                    target="_blank"
                                    className="text-lg border border-StandardSEPM p-2 flex-shrink-0 w-16 h-16 flex justify-center items-center transition-transform duration-300 ease-in-out hover:scale-110"
                                >
                                    {DOWNLOADS.pdfIcon_GE}
                                </a>

                                {/* File Name */}
                                <div className="text-center mt-2 text-sm">Release Notes German</div>
                            </div>
                        </motion.div>
                    </motion.div>

                </motion.div>






            </div>
        </section>
    )
}

export default DownloadLinks