import { HOW_IT_WORKS_CONTENT } from "../constants";
import { motion } from 'framer-motion';


const HowItWorks = () => {
    const stepVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.6,
                ease: "easeOut"
            }
        })
    }
    return (
        <section id="works">
            <div className="max-w-7xl mx-auto px-4">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className="text-center mb-12 border-t border-neutral-800"
                >
                    <h2 className="text-3xl lg:text-6xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {HOW_IT_WORKS_CONTENT.sectionTitle}
                    </h2>
                    <p className="mt-4 text-[16px] font-medium text-neutral-500 max-w-xl mx-auto">
                        {HOW_IT_WORKS_CONTENT.sectionDescription}
                    </p>
                </motion.div>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={{
                        visible: {
                            transition: {
                                staggerChildren: 0.2
                            }
                        }
                    }}
                    className=""
                >
                    {HOW_IT_WORKS_CONTENT.steps.map((step, index) => (
                        <motion.div
                            custom={index}
                            variants={stepVariants}
                            key={index}
                            className="flex flex-col justify-between"
                        >
                            {/* Heading with Icon */}
                            <div className="flex items-start mb-4 space-y-4 ">
                                <div className="text-StandardSEPM bg-StandardSEPM_fade2 p-3 rounded-full flex-shrink-0 mt-4">
                                    {step.icon}
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-medium font-Montserrat">{step.title}</h3>
                                    <p className="text-neutral-500 font-medium mt-2">{step.description}</p>
                                </div>
                            </div>


                        </motion.div>
                    ))}
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className="text-center mt-8 "
                >
                    <p className="font-semibold text-[1rem] text-neutral-600 max-w-xl mx-auto">
                        {HOW_IT_WORKS_CONTENT.lastStep}
                    </p>
                </motion.div>
            </div>
        </section>
    )
}

export default HowItWorks






{/* <motion.div
initial='hidden'
whileInView='visible'
variants={{
    visible: {
        transition: {
            straggerChildren: 0.2
        }
    }
}}
className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
>
{HOW_IT_WORKS_CONTENT.steps.map((step, index) => (
    <motion.div
        custom={index}
        variants={stepVariants}
        key={index}
        className="bg-white p-6 rounded-xl shadow-lg flex flex-col justify-between"
    >
        <div>
            <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
            <p className="text-neutral-400 mb-4">{step.description}</p>
        </div>

        <div className="flex justify-center">
            <img src={step.imageSrc} alt={step.imageAlt} className="rounded-lg" />
        </div>

        {step.users && (
            <div className="flex justify-between items-center mt-4">
                <div className="flex -space-x-2">
                    {step.users.map((user, userIndex) => (
                        <img key={userIndex} src={user} alt={`Person ${userIndex + 1}`} className="h-8 w-8 rounded-full border-2 border-black" />
                    ))}
                </div>
                <button className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded-lg">Connect</button>
            </div>
        )}
    </motion.div>
))}
</motion.div> */}