import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css/pagination';
import { OUR_PARTNERS } from '../constants';



const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { straggerChildren: 0.2 } },
}

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
}

const Partners = () => {
    return (
        <motion.section id="partners" variants={containerVariants} initial='hidden' animate='visible' className="pt-2 lg:pt-2 mb-24 ">
            <div className="max-w-7xl mx-auto px-4 flex flex-col items-center text-center border-t border-neutral-800">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className="text-center mb-12 "
                >
                    <h2 className="text-3xl lg:text-6xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {OUR_PARTNERS.sectionTitle}
                    </h2>
                    <p className="mt-4 text-lg font-medium text-neutral-500 max-w-xl mx-auto">
                        {OUR_PARTNERS.sectionDescription}
                    </p>
                </motion.div>
                <motion.div variants={fadeIn} className='py-10 w-full md:w-1/2 lg:w-full'>
                    <div className="relative">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={20} // Add space between the slides
                            pagination={{
                                clickable: true,
                                el: ".custom-pagination", // Link to a custom pagination container
                            }}
                            modules={[Pagination]}
                            className="mySwiper text-neutral-700"
                        >
                            {OUR_PARTNERS.logos.map((logo, index) => (
                                <SwiperSlide
                                    key={index}
                                    className="p-4 bg-white shadow-md rounded-lg hover:shadow-lg transition-all duration-300"
                                >
                                    <a href={logo.redirectLink} target="_blank">
                                        <motion.img
                                            src={logo.src}
                                            alt={logo.alt}
                                            className="h-20 w-auto object-contain mx-auto" // Centered image with uniform padding
                                            variants={fadeInUp}
                                        />
                                    </a>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {/* Custom Pagination */}
                        <div className="custom-pagination mt-8 flex justify-center"></div>
                    </div>

                </motion.div>



            </div>
        </motion.section>
    )
}

export default Partners