import { BRAND_LOGOS, HERO_CONTENT } from '../constants';
import heroImage from '../assets/heroImg.png';
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css/pagination';



const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { straggerChildren: 0.2 } },
}

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
}

const flash = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
        opacity: [1, 0.5, 1], // Pulsing effect by alternating opacity
        scale: [1, 1.1, 1],  // Slight zoom in and out
        transition: {
            duration: 1.5,    // Duration of one complete flash cycle
            repeat: Infinity, // Infinite repetition
            repeatType: "mirror", // Smoothly reverses the animation
            ease: "easeInOut" // Smooth transition
        },
    },
};

const subtleFlash = {
    hidden: { opacity: 0 },
    visible: {
        opacity: [1, 0.8, 1], // Slight fade in and out
        scale: [1, 1.05, 1],  // Subtle scaling effect
        transition: {
            duration: 1.5,    // Smooth and slow transition
            repeat: Infinity, // Continuous animation
            repeatType: "mirror", // Reverses the animation for smooth looping
            ease: "easeInOut", // Adds smooth easing
        },
    },
};



const HeroSection = () => {
    return (
        <motion.section variants={containerVariants} initial='hidden' animate='visible' className="pt-28 lg:pt-36">
            <div className="max-w-7xl mx-auto px-4 flex flex-col items-center text-center">
            
                <motion.a
                    variants={flash}
                    initial="hidden"
                    animate="visible"
                    className="mt-4 mb-8 border-neutral-800 px-3 py-2 rounded-full font-semibold text-lg bg-yellow-300 text-black"
                    href='#new_feature'
                >
                    {HERO_CONTENT.badgeText}
                </motion.a>
                {/* <motion.div
                    variants={subtleFlash}
                    initial="hidden"
                    animate="visible"
                    className="mb-8 border-neutral-800 px-3 py-2 rounded-full font-semibold text-sm"
                >
                    {HERO_CONTENT.badgeText}
                </motion.div> */}

                <motion.h1 variants={fadeInUp} className='text-5xl lg:text-7xl my-6 font-semibold tracking-tighter  text-neutral-700 bg-clip-text '>
                    {HERO_CONTENT.mainHeading.split("\n").map((text, index) => (
                        <span key={index}>
                            {text}
                            <br />
                        </span>
                    ))}
                </motion.h1>

                <motion.p variants={fadeInUp} className='font-medium mt-6 text-neutral-700 max-w-xl text-lg'>
                    {HERO_CONTENT.subHeading}
                </motion.p>

                {/* <motion.div variants={fadeInUp} className='mt-6 space-x-4'>
                    <a href='#' className='inline-block bg-StandardSEPM hover:bg-[#cd5d48] text-white py-3 px-6 rounded-lg font-medium'>
                        {HERO_CONTENT.callToAction.primary}
                    </a>
                    <a href='#' className='inline-block border border-StandardSEPM hover:border-gray-400 text-StandardSEPM hover:text-gray-500 py-3 px-6 rounded-lg font-medium'>
                        {HERO_CONTENT.callToAction.secondary}
                    </a>
                </motion.div> */}



                <motion.div variants={fadeIn} className='mt-12'>
                    <img src={heroImage} alt='Streamer Saas Interface' className='w-full h-auto rounded-3xl border border-neutral-800 mb-20' />
                </motion.div>

                <motion.p variants={fadeInUp} className='font-semibold text-md mt-6 text-neutral-700 max-w-3xl mb-16'>
                    {HERO_CONTENT.msgText.split("\n").map((text, index) => (
                        <span key={index}>
                            {text}
                            <br />
                        </span>
                    ))}
                </motion.p>

                {/* <motion.div variants={fadeIn} className='py-10'>
                    <p className='text-[#da8576] text-center mb-8 font-medium'>
                        {HERO_CONTENT.trustedByText}
                    </p>
                    <motion.div variants={fadeInUp} className='flex flex-wrap justify-center gap-8'>
                        {
                            BRAND_LOGOS.map((logo, index) => (
                                <img key={index} src={logo.src} alt={logo.alt} className='h-20' />
                            ))
                        }
                    </motion.div>
                </motion.div> */}

            </div>
        </motion.section>
    )
}

export default HeroSection



