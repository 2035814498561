import NavBar from "./Components/NavBar";
import HeroSection from "./Components/HeroSection";
import HowItWorks from "./Components/HowItWorks";
import KeyFeatures from "./Components/KeyFeatures";
import UnderConstruction from "./Components/UnderConstruction";
import PricingPlans from "./Components/PricingPlans";
import Testimonials from "./Components/Testimonials";
import Footer from "./Components/Footer";
import Partners from "./Components/Partners";
import NewFeature from "./Components/NewFeature";
import DownloadLinks from "./Components/DownloadLinks";


function App() {
  return (
    <main className="text-sm text-StandardSEPM antialiased">

      <NavBar />
      <HeroSection />
      <HowItWorks />
      <KeyFeatures />
      <NewFeature />
      <DownloadLinks />
      <Partners />
      <Testimonials />
      <Footer />
      {/* <UnderConstruction /> */}

    </main>

  );
}

export default App;













{/* <NavBar />
      <HeroSection />
      <HowItWorks />
      <KeyFeatures /> */}


{/* <PricingPlans /> */ }
{/* <Testimonials /> */ }
{/* <Footer /> */ }