import { delay, motion } from 'framer-motion';
import { TESTIMONIALS_CONTENT } from '../constants';

const Testimonials = () => {
    const testimonialVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.6,
                ease: "easeOut"
            }
        })
    }
    return (
        <section id='testimonials'>
            <div className='max-w-7xl mx-auto px-4 mt-20'>
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className='text-center mb-12 border-t border-StandardSEPM'
                >
                    <h2 className="text-3xl lg:text-5xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {TESTIMONIALS_CONTENT.sectionTitle}
                    </h2>
                    <p className='mt-4 text-lg'>
                        {TESTIMONIALS_CONTENT.sectionDescription}
                    </p>
                </motion.div>

                <motion.div
                    initial='hidden'
                    whileInView='visible'
                    variants={{
                        visible: {
                            transition: {
                                straggerChildren: 0.2
                            }
                        }
                    }}
                    className='grid grid-cols-1 md:grid-cols-3 gap-8 '
                >
                    {TESTIMONIALS_CONTENT.reviews.map((review, index) => (
                        <motion.div
                            key={index}
                            custom={index}
                            variants={testimonialVariants}
                            className='mt-10 flex flex-col items-center justify-evenly overflow-hidden rounded-2xl bg-white border border-StandardSEPM p-10'
                        >
                            <p className='mb-4 text-neutral-800'>{review.review}</p>
                            <div className='flex items-center mt-4'>
                                <img src={review.image} alt={review.alt} className='w-12 h-12 rounded-full border border-neutral-400 mr-4' />
                                <div>
                                    <p className='text-sm font-bold text-neutral-800'>{review.name}</p>
                                    <p className='text-sm text-gray-500'>{review.title}</p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    )
}

export default Testimonials