import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { RiCloseFill, RiMenu3Line } from '@remixicon/react';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className='fixed top-1 left-0 right-0 z-50 m-2'>
            <div className='text-StandardSEPM bg-white backdrop-blur-md max-w-7xl mx-auto px-4 py-3 flex justify-between items-center rounded-xl border border-StandardSEPM'>
                {/* left:logo */}
                <img src="https://sepm-bucket.s3.eu-west-3.amazonaws.com/SEPM+Logo+Rund.png" alt='logo' width={80} height={20} />

                {/* center: Links(hidden on mobile) */}
                <div className='hidden md:flex space-x-6 text-[15px]'>
                    <a href='#works' className='hover:font-medium'>
                        Test SEPM today !
                    </a>
                    <a href='#products' className='hover:font-medium'>
                        Products
                    </a>
                    <a href='#partners' className='hover:font-medium'>
                        Our Partners
                    </a>
                    <a href='#testimonials' className='hover:font-medium'>
                        Testimonials
                    </a>
                    <a href='#downloads' className='hover:font-medium'>
                        Release Notes
                    </a>
                </div>

                {/* right: Buttons (hidden on mobile) */}
                <div className='hidden md:flex space-x-4 items-center'>
                    {/* <a href='#' className='hover:font-medium'>
                        Login
                    </a> */}
                    {/* <a href='#' className='border border-StandardSEPM text-StandardSEPM hover:text-white py-2 px-4 rounded-lg hover:bg-StandardSEPM transition'>
                        Get a Demo
                    </a> */}
                    <a href='#downloads' className='bg-StandardSEPM text-white py-2 px-4 rounded-lg hover:bg-[#cd5d48] transition'>
                        Download
                    </a>
                </div>

                {/* Hamburger icon for mobile */}
                <div className='md:hidden'>
                    <button onClick={toggleMenu} className='text-StandardSEPM focus:outline-none' aria-label={isOpen ? "Close Menu" : "Open Menu"}>
                        {isOpen ? <RiCloseFill /> : <RiMenu3Line />}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className='md:hidden bg-StandardSEPM_fade5 backdrop-blur-md border border-StandardSEPM p-4 rounded-xl mt-2'>
                    <div className='flex flex-col space-y-4'>
                        <a href='#works' className='hover:font-medium'>Test SEPM today !</a>
                        <a href='#products' className='hover:font-medium'>Products</a>
                        <a href='#partners' className='hover:font-medium'>Our Partners</a>
                        <a href='#testimonials' className='hover:font-medium'>Testimonials</a>
                        <a href='#testimonials' className='hover:font-medium'>Release Notes</a>

                        {/* <a href='#' className='hover:text-white'>Login</a>
                        <a href='#' className='border border-StandardSEPM text-StandardSEPM hover:text-white py-2 px-4 rounded-lg hover:bg-StandardSEPM  transition'>
                            Get a Demo
                        </a> */}
                        <a href='#' className='bg-StandardSEPM text-white py-2 px-4 rounded-lg hover:bg-[#cd5d48] transition'>
                            Download
                        </a>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default NavBar



{/* <div className="sticky top-0 bg-white z-50 shadow-md">
<nav className="flex items-center justify-between px-4 py-3">
    <div className="text-StandardSEPM text-lg font-bold">SEPM</div>

</nav>
</div> */}