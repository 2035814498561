import { HOW_IT_WORKS_CONTENT, NEW_FEATURE } from "../constants";
import { motion } from 'framer-motion';


const NewFeature = () => {
    const stepVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.6,
                ease: "easeOut"
            }
        })
    }

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    }


    return (
        <section id="new_feature">
            <div className="max-w-7xl mx-auto px-4 border-t border-neutral-800 mb-8">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeOut" }}
                    className="text-center mb-12 "
                >
                    <h2 className="text-3xl lg:text-6xl mt-20 tracking-tighter text-neutral-600 bg-clip-text ">
                        {NEW_FEATURE.sectionTitle}
                    </h2>
                    <p className="mt-4 text-[16px] font-medium text-StandardSEPM max-w-xl mx-auto">
                        {NEW_FEATURE.sectionDescription}
                    </p>
                </motion.div>
                <p className="mt-4 text-[14px] font-medium text-neutral-800">
                    This release 2024-02 covers the following improvements:
                </p>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={{
                        visible: {
                            transition: {
                                staggerChildren: 0.2,
                            },
                        },
                    }}
                    className="flex flex-wrap md:flex-nowrap items-start gap-6"
                >
                    {/* Left Section: Features */}
                    <motion.div className="flex flex-col space-y-6 w-full md:w-1/2 mt-8">
                        {NEW_FEATURE.features.map((feature, index) => (
                            <motion.div
                                custom={index}
                                variants={stepVariants}
                                key={index}
                                className="flex flex-col space-y-1"
                            >
                                {/* Feature Heading with Icon */}
                                <div className="flex items-start">
                                    <div className="text-lg flex-shrink-0">
                                        🚀 {/* Feature Icon */}
                                    </div>
                                    <div className="ml-4">
                                        <p className="font-semibold text-StandardSEPM">{feature.title}</p>
                                    </div>
                                </div>

                                {/* Feature Pointers */}
                                <ul className="ml-12 list-disc space-y-3">
                                    {feature.description.map((pointer, idx) => (
                                        <li
                                            key={idx}
                                            className="text-neutral-700 font-medium"
                                        >
                                            {pointer}
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </motion.div>


                    {/* Right Section: Image */}
                    <motion.div
                        variants={fadeInUp}
                        className="w-full md:w-1/2 flex justify-center items-center"
                    >
                        <motion.img
                            src={NEW_FEATURE.featureImg.src}
                            alt={NEW_FEATURE.featureImg.alt}
                            className="max-w-full h-auto object-contain"
                        />
                    </motion.div>
                </motion.div>






            </div>
        </section>
    )
}

export default NewFeature