
import Geomagic from "../assets/geomagic.png";
import Mettermeier from "../assets/logo-mettenmeier.png";
import NIS from "../assets/nis-logo.png";
import ITS from "../assets/its-logo.png";
import IST from "../assets/ist-logo.jpg";
import RKUIT from "../assets/rkuit-logo.svg";
import SWA from "../assets/small_world_alliance.png";
import GIS from "../assets/GIS-logo.png";
import SPIE from "../assets/SPIE.png"
import ESN from "../assets/ESN.png";
import RealWorld from "../assets/RWS-Logo.png";

import user from "../assets/dummyUser.webp";
import newfeature from "../assets/SEPM-NewFeature.jpg";

import Xtranslator from "../assets/pdfs/X-Translator_Flyer_EN.pdf";
import XRaster from "../assets/pdfs/X-Raster_Flyer_EN.pdf";
import Interlis from "../assets/pdfs/INTERLIS_Flyer_EN.pdf";
import Isybau from "../assets/pdfs/ISYBAU_Flyer_EN.pdf";
import Neplan from "../assets/pdfs/NEPLAN_Flyer_EN.pdf";
import Services from "../assets/pdfs/Services_Flyer_EN.pdf";
import NewFeatureNoteGerman from "../assets/pdfs/NewFeatureNote_GE.pdf";
import NewFeatureNoteEnglish from "../assets/pdfs/NewFeatureNote_EN.pdf";

import {
  RiBarChart2Line,
  RiSettings2Line,
  RiTeamLine,
  RiTwitchLine,
  RiYoutubeLine,
  RiCalendarLine,
  RiDownloadCloud2Line,
  RiSurveyLine,
  RiShakeHandsLine,
  RiFileCheckLine,
  RiOpenArmLine,
  RiFileDownloadFill,
  RiFilePdf2Fill,
  RiFilePdf2Line
} from "@remixicon/react";

export const HERO_CONTENT = {
  badgeText: "🚀 SEPM Products 2024-02 Available Now!",
  mainHeading: "Streamlining GIS Workflows \n for Smallworld Users",
  subHeading:
    "Imagine a GIS tool so intuitive, your team spends less time fixing errors and more time innovating.",
  callToAction: {
    primary: "Start Free Trial",
    secondary: "Request a Demo",
  },
  msgText: "Smallworld GIS users often struggle with inefficient tools for data conversion that cause delays and errors.\n\n At SEPM, \n we simplify GIS data management so engineers can focus on decisions, not technical hurdles",
  trustedByText: "Trusted by Leading GIS Consultants & Smallworld Users",
};

export const BRAND_LOGOS = [
  { src: Geomagic, alt: "Geomagic" },
  { src: Mettermeier, alt: "Mettermeier" },
  { src: NIS, alt: "NIS" },
  { src: ITS, alt: "ITS" },
  { src: 'https://sepm-bucket.s3.eu-west-3.amazonaws.com/diakinotiki.png', alt: "diakinotiki" },
  { src: 'https://esn.de/wp-content/uploads/2019/02/ESN-EnergieSystemeNord-Logo-55x342.png', alt: "ESN" }
];

export const OUR_PARTNERS = {
  sectionTitle: "Our Partners",
  sectionDescription:
    "Empowering success through our trusted network of strategic partners",
  logos: [
    { src: 'https://sepm-bucket.s3.eu-west-3.amazonaws.com/diakinotiki.png', alt: "diakinotiki", redirectLink: 'http://www.diakinotiki.gr/' },
    { src: ESN, alt: "ESN", redirectLink: 'https://esn.de/' },
    { src: Geomagic, alt: "Geomagic", redirectLink: 'https://www.geomagic.de/' },
    { src: GIS, alt: "GIS Consult", redirectLink: 'https://www.gis-consult.de/' },
    { src: IST, alt: "IST", redirectLink: 'https://ist.co.za/' },
    { src: ITS, alt: "ITS", redirectLink: 'https://its-service.de/' },
    { src: Mettermeier, alt: "Mettermeier", redirectLink: 'https://www.mettenmeier.de/' },
    { src: NIS, alt: "NIS", redirectLink: 'https://nis.ch/' },
    { src: RealWorld, alt: 'Real World', redirectLink: 'https://realworld-systems.com/' },
    { src: RKUIT, alt: "RKUIT", redirectLink: 'https://rku-it.de/' },
    { src: SPIE, alt: "SPIE", redirectLink: 'https://spie.de/' },
    { src: SWA, alt: "SWA", redirectLink: 'https://www.smallworld-alliance.com/' },
  ]
};

export const HOW_IT_WORKS_CONTENT = {
  sectionTitle: "Test the SEPM X-Translator Today!",
  sectionDescription:
    "Getting Started is Easy – Follow These Steps :",
  steps: [
    {
      title: "Download the SEPM Product",
      description:
        "Use the link below to download the SEPM X-Translator",
      icon: <RiDownloadCloud2Line className="w-8 h-8" />
    },
    {
      title: "Activate Your SEPM License",
      description:
        "Contact SEPM or one of our trusted partners to receive the password for the downloaded zip file and instructions for adding the SEPM product to your Smallworld session",
      icon: <RiFileCheckLine className="w-8 h-8" />
    },
    {
      title: "Review the Documentation",
      description:
        "Follow the Quickguide.pdf and additional SEPM documentation to test importing and exporting data. Try using common file formats such as DXF, GeoJSON, Shape, or KML.",
      icon: <RiSurveyLine className="w-8 h-8" />
    },
    {
      title: "Explore the Features",
      description:
        "We’re here to help! SEPM or our trusted partners can guide you through the basics and demonstrate advanced functionalities tailored to your needs.",
      icon: <RiShakeHandsLine className="w-8 h-8" />
    },
    {
      title: "Complete Onboarding",
      description:
        "Collaborate with SEPM or a sales partner to ensure successful setup and integration into your workflow.",
      icon: <RiOpenArmLine className="w-8 h-8" />
    },
  ],
  lastStep: 'Start Your SEPM Journey Today and Revolutionize Your GIS Workflows!'
};

export const KEY_FEATURES_CONTENT = {
  sectionTitle: "Our Product Suite for Seamless GIS Workflows",
  sectionDescription:
    "With over 250 businesses worldwide relying on our solutions, we’ve created tools designed to streamline GIS workflows",
  features: [
    {
      id: 1,
      icon: <RiBarChart2Line className="w-8 h-8" />,
      title: "SEPM X-Translator",
      description: "User-friendly tool for seamless vector data import/export",
      pdfPath: Xtranslator

    },
    {
      id: 2,
      icon: <RiSettings2Line className="w-8 h-8" />,
      title: "SEPM X-Raster",
      description: "Raster data import/export",
      pdfPath: XRaster
    },
    {
      id: 3,
      icon: <RiTeamLine className="w-8 h-8" />,
      title: "SEPM NEPLAN Interface",
      description: "Simplified network analysis integration",
      pdfPath: Neplan
    },
    {
      id: 4,
      icon: <RiTwitchLine className="w-8 h-8" />,
      title: "SEPM INTERLIS Interface",
      description: "Seamlessly bridging GIS systems with robust INTERLIS import/export for Swiss data models",
      pdfPath: Interlis
    },
    {
      id: 5,
      icon: <RiYoutubeLine className="w-8 h-8" />,
      title: "SEPM ISYBAU Interface",
      description: "Effortlessly integrate ISYBAU XML data with Smallworld NRM Wastewater to import and export field inspection data coded per DWA-M 149-2 or DIN EN 13508-2",
      pdfPath: Isybau
    },
    {
      id: 6,
      icon: <RiCalendarLine className="w-8 h-8" />,
      title: "SEPM Services",
      description: "From GIS migrations to custom software solutions, SEPM delivers innovative, precise, and future-ready services tailored to your geospatial needs",
      pdfPath: Services
    },
  ],
};

export const NEW_FEATURE = {
  sectionTitle: "New Feature : Discover What's New !!",
  sectionDescription: "Experience the next level of functionality with our latest updates",
  featureImg: { src: newfeature, alt: "NewFeature" },
  features: [
    {
      title: 'SEPM X-Translator',
      description: [
        "Official support for GE Vernova Smallworld Geo Network Management GeoSpatial Server (GSS) technology.",
        "Various improvements in the user interface and formats."
      ]
    },
    {
      title: 'SEPM INTERLIS Interfaces',
      description: [
        "New interface for mapping NRM Pipeline data to Swiss federal data models (PipelinesystemUnderSupervisionByFederalGovernment_V1.ili and PipelinesystemUnderSupervisionByFederalGovernmentExtended_V1.ili), including combined export functionality.",
        "Improved export configuration for Protected Areas for Pipe Storage layouts"
      ]
    },
    {
      title: 'SEPM NEPLAN Interface',
      description: [
        "Support for Prosumer categories",
        "Configuration of the NEPLAN Type field for generated ExternalGrid elements"
      ]
    },
    {
      title: 'SEPM ISYBAU Interface',
      description: [
        "New Network Update functionality for NRM Wastewater, supporting the import of ISYBAU inspection data (types BCA, DCA, and DCG) and matching or creating house service connections in the GIS system"
      ]
    },

  ]
};

export const DOWNLOADS = {
  sectionTitle: "Product Downloads and Support",
  sectionDescription: "Experience our newest release — get it here",
  downloadText: "Download Link",
  downloadLink: "https://sepm-bucket.s3.eu-west-3.amazonaws.com/SEPM-Release/sepm_24_2_0_source.zip",
  downloadFile: "sepm_24_2_0_source.zip",
  downloadIcon: <RiFileDownloadFill className="w-14 h-14" />,
  pdfIcon_EN: <RiFilePdf2Line className="w-14 h-14" />,
  pdfIcon_GE: <RiFilePdf2Fill className="w-14 h-14" />,
  downloadPassword: "Bordeaux",
  releaseNote_GE: NewFeatureNoteGerman,
  releaseNote_EN: NewFeatureNoteEnglish,
};

export const PLANS_CONTENT = {
  sectionTitle: "Choose Your Plan",
  sectionDescription:
    "Streamerzz offers flexible pricing plans to fit every streamer’s needs, from beginner to pro.",
  popularBadge: "Most Popular",
  ctaText: "Get Started",
  plans: [
    {
      name: "Basic",
      price: "$9.99/month",
      description:
        "Perfect for beginners just starting their streaming journey.",
      features: [
        "Basic analytics",
        "Custom overlays",
        "Viewer engagement tools",
        "Stream up to 720p",
      ],
    },
    {
      name: "Pro",
      price: "$19.99/month",
      description:
        "For streamers who want more advanced tools to grow their audience.",
      features: [
        "Advanced analytics",
        "Custom alerts & notifications",
        "HD streaming up to 1080p",
        "Real-time audience insights",
        "Unlimited support",
      ],
      popular: true,
    },
    {
      name: "Elite",
      price: "$49.99/month",
      description:
        "For professional streamers who need cutting-edge tools and insights.",
      features: [
        "Premium analytics & reporting",
        "4K Ultra HD streaming",
        "Dedicated account manager",
        "Full API access",
        "Custom branding & overlays",
      ],
    },
  ],
};

export const TESTIMONIALS_CONTENT = {
  sectionTitle: "What Our Customers Say",
  sectionDescription:
    `"Hear from some of our valued users as they share their experiences and stories about how our solutions have made a difference. Their feedback inspires us to continually improve and deliver exceptional value."`,
  reviews: [
    {
      name: "Erwin Sägesser NIS AG",
      title: "NIS AG",
      review:
        "One single point of truth is possible – The SEPM-Interfaces eliminate time consuming post-processing work after complex data transformations. For this reason SEPM enables customers the efficient reuse of their quality-assured data in our network information system NIS Strom",
      image: user,
    },
    {
      name: "Mario Schuler EW Schwyz",
      title: "",
      review:
        "The network information needs no longer be entered by hand into NEPLAN in a time consuming process. It is managed centrally in NIS Strom and can be transferred comfortably with the SEPM NEPLAN Interface. Redundant and error-prone database maintenance is no longer necessary.",
      image: user,
    },
    {
      name: "Bernd Großmann City of Leverkusen",
      title: "",
      review:
        "User-friendly, straightforward and to the point: SEPM X-Translator have not only proven their usefulness for standard import and export tasks. Tables with coordinates are converted into point objects with annotations in no time. Transferring data from Smallworld to Smallworld expands the bag of tricks with an interesting instrument",
      image: user,
    },
    {
      name: "Davy Bielen Fluvius",
      title: "",
      review:
        "Due to an easy to use SEPM X-Translator we were able to streamline business processes",
      image: user,
    },
    {
      name: "Eric Kappmeier MID",
      title: "",
      review:
        "Our Smallworld users have been using SEPM X-Translator to convert Shape and DXF/DWG data in and out of our Smallworld GIS database for a number of years. We believe it is, hands-down, the easiest, most accurate, and most cost-effective tool for sharing spatial data between Smallworld and other GIS systems.",
      image: user,
    },

  ],
};

export const FOOTER_CONTENT = {

  contactUsText: 'CONTACT US',
  contactUsDesc: 'Robert Schweikert \n Geliswilstrasse 42, CH-6020 Emmenbrücke, Switzerland, \n +41 79 632 28 20, \n info@sepm.ch',
  platformsText:
    "SEPM Products | X-Translator | X-Raster | NEPLAN | INTERLIS | ISYBAU",
  copyrightText: "Copyright © 2024 SEPM, Inc. All rights reserved.",

  sections: [
    {
      title: "CONNECT WITH US",
      links: [
        // { text: "Twitter", url: "#" },
        // { text: "Facebook", url: "#" },
        { text: "LinkedIn", url: "https://www.linkedin.com/company/sepm-software-engineering-and-project-management" },
        { text: "SEPM Support", url: "https://sepmworldwide.zendesk.com/" },
        // { text: "Twitch", url: "#" },
        // { text: "Discord", url: "#" },
      ],
    },
    // {
    //   title: "TOOLS & SERVICES",
    //   links: [
    //     { text: "SEPM X-Translator", url: "#" },
    //     { text: "SEPM X-Raster", url: "#" },
    //     { text: "SEPM NEPLAN Interface", url: "#" },
    //     { text: "SEPM INTERLIS Interface", url: "#" },
    //     { text: "SEPM ISYBAU Interface", url: "#" },
    //     { text: "Mobile Streaming Support", url: "#" },
    //     { text: "4K Stream Capabilities", url: "#" },
    //     { text: "Stream Scheduling Tool", url: "#" },
    //   ],
    // },
    // {
    //   title: "SUPPORT & RESOURCES",
    //   links: [
    //     { text: "Subscription Plans", url: "#" },
    //     { text: "Affiliate Program", url: "#" },
    //     { text: "Frequently Asked Questions", url: "#" },
    //     { text: "Company Blog", url: "#" },
    //     { text: "Subscribe to Newsletter", url: "#" },
    //     { text: "Latest Features", url: "#" },
    //     { text: "Merchandise Store", url: "#" },
    //     { text: "Workshops & Events", url: "#" },
    //   ],
    // },

    // {
    //   title: "LEARN & EXPLORE",
    //   links: [
    //     { text: "Engage Viewers with Custom Alerts", url: "#" },
    //     { text: "Top Streaming Strategies for New Streamers", url: "#" },
    //     { text: "How to Stream in 4K for Maximum Quality", url: "#" },
    //     { text: "Efficient Stream Scheduling Techniques", url: "#" },
    //     { text: "Using Analytics to Boost Engagement", url: "#" },
    //     { text: "Create Stunning Overlays for Streams", url: "#" },
    //     { text: "Advanced Analytics for Streamers: A Guide", url: "#" },
    //   ],
    // },
  ],

};




        